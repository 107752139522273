<style scoped lang="less">
.cmSelect-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>

<template>
  <div class="cmSelect-content">
    <div v-if="comboExpand == false" style="flex: 1">
      <el-select
        v-if="multiple"
        v-model="valueList"
        multiple
        filterable
        allow-create
        default-first-option
        size="mini"
        :clearable="true"
        :disabled="disabled"
        @change="selectChange"
        @keydown.native="handleKeydown"
      >
        <el-option
          v-for="item in tableList"
          :key="item.detailIndex"
          :label="item[configs.label]"
          :value="item[configs.value]"
        ></el-option>
      </el-select>
      <el-select
        v-if="!multiple && tableList.length > max"
        v-model="innerValue"
        filterable
        default-first-option
        allow-create
        size="mini"
        :clearable="true"
        :disabled="disabled"
        @keydown.native="handleKeydown"
      >
        <el-option
          v-for="item in tableList"
          :key="item.detailIndex"
          :label="item[configs.label]"
          :value="item[configs.value]"
        ></el-option>
      </el-select>
      <el-radio-group
        style="flex: 1"
        v-if="!multiple && tableList.length <= max"
        v-model="innerValue"
      >
        <el-radio
          v-for="(item, index) in tableList"
          :disabled="disabled"
          @click.native.prevent="radioChange(item[configs.label], index)"
          :key="index"
          :label="item[configs.value]"
        >
          {{ item[configs.label] }}
        </el-radio>
      </el-radio-group>
    </div>
    <div v-if="comboExpand == true" style="flex: 1">
      <el-radio-group style="flex: 1" v-if="!multiple" v-model="innerValue">
        <el-radio
          v-for="(item, index) in tableList"
          :disabled="disabled"
          @click.native.prevent="radioChange(item[configs.label], index)"
          :key="index"
          :label="item[configs.value]"
        >
          {{ item[configs.label] }}
        </el-radio>
      </el-radio-group>
      <el-checkbox-group v-if="multiple" v-model="valueList" style="flex: 1">
        <el-checkbox
          v-for="(item, index) in tableList"
          :disabled="disabled"
          :key="index"
          :label="item[configs.value]"
        >
          {{ item[configs.label] }}
        </el-checkbox>
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CmSelect',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    activeUrl: {
      // 懒加载地址
      type: String,
      default: ''
    },
    expand: {
      // 是否可展开，true&&len<max展示成radio
      type: Boolean,
      default: true
    },
    max: {
      type: Number,
      default: 2
    },
    group: {
      type: String,
      default: ''
    },

    config: {
      // 配置label，value
      type: Object,
      default: () => {
        return {
          id: 'id',
          label: 'comboValue',
          value: 'comboValue'
        }
      }
    },

    value: {
      type: [String, Number],
      require: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tableList: [],
      valueList: [],
      // userList: this.innerValue,
      innerValue: '',
      multiple: true,
      comboExpand: 0,
      filterable: false,
      configs: this.config
    }
  },
  watch: {
    /* 监控数据变化 */

    activeUrl: {
      handler(newVal) {
        if (newVal) {
          this.getActiveUrlData()
        }
      },
      immediate: true
    },
    value: {
      handler(val) {
        if (typeof val === 'number') val += ''
        if (val) {
          this.valueList = val.split('|')
        } else {
          this.valueList = []
        }
        this.innerValue = val
      },
      immediate: true
    },
    innerValue: {
      handler(val) {
        this.$emit('change', val)
      },
      immediate: true
    },
    valueList: {
      handler(val) {
        this.$emit('change', val.join('|'))
      },
      immediate: true
    },
    config: function (val) {
      this.configs = val
    }
  },
  created() {},
  mounted() {},
  methods: {
    addDetail() {
      let formData = new FormData()
      formData.append('comboValue', this.innerValue)
      this.$api
        .post(`/v1/webconsole/comboData/add/detail/${this.tableList[0].comboUID}`, formData)
        .then((res) => {
          this.$message.success(res.data.msg)
        })
    },
    handleKeydown(event) {
      const keyCode = event.keyCode
      // Enter
      if (keyCode === 13) {
        this.addDetail()
        event.stopPropagation()
        return
      }
    },
    selectChange(val) {
      if (val.length > 0 && val.includes('无')) {
        if (val[val?.length - 1] === '无') {
          this.valueList = ['无']
        } else {
          this.valueList = val?.filter((item) => item !== '无')
        }
      }
    },
    radioChange(val) {
      if (val === this.innerValue) {
        this.innerValue = ''
      } else {
        this.innerValue = val
      }
    },
    getActiveUrlData() {
      this.$api.get(this.activeUrl).then((res) => {
        // 这里是手术
        if (this.activeUrl.includes('oc=0')) {
          this.configs = {
            id: 'dictIndex',
            label: 'dictName',
            value: 'dictName'
          }
        } else if (this.activeUrl.includes('oc=1')) {
          this.configs = {
            id: 'dictIndex',
            label: 'dictName',
            value: 'dictName'
          }
        } else if (this.activeUrl.includes('userType=技术员')) {
          this.configs = {
            id: 'userID',
            label: 'userName',
            value: 'userCode'
          }
        } else if (this.activeUrl.includes('userType=护士')) {
          this.configs = {
            id: 'userID',
            label: 'userName',
            value: 'userName'
          }
        } else if (this.activeUrl.includes('userType=医生')) {
          this.configs = {
            id: 'userID',
            label: 'userName',
            value: 'userName'
          }
        } else if (this.activeUrl.includes('comboUID=ssjbxx_AdmissionType')) {
          this.configs = {
            id: 'comboDataIndex',
            label: 'comboValue',
            value: 'comboValue'
          }
        } else if (this.activeUrl.includes('doctors')) {
          this.configs = {
            id: 'userID',
            label: 'userName',
            value: 'userName'
          }
        }

        if (this.activeUrl.includes('oc=0')) {
          this.tableList = res.data.data.map((item) => {
            return {
              ...item,
              id: item[this.configs.id],
              label: item[this.configs.label],
              value: item[this.configs.value]
            }
          })
        } else if (this.activeUrl.includes('oc=1')) {
          this.tableList = res.data.data.map((item) => {
            return {
              ...item,
              id: item[this.configs.id],
              label: item[this.configs.label],
              value: item[this.configs.value]
            }
          })
        } else if (this.activeUrl.includes('doctors')) {
          this.tableList = res.data.data.map((item) => {
            return {
              ...item,
              id: item[this.configs.id],
              label: item[this.configs.label],
              value: item[this.configs.value]
            }
          })
        } else {
          this.tableList = res.data.data.map((item) => {
            return {
              ...item,
              id: item[this.configs.id],
              label: item[this.configs.label],
              value: item[this.configs.value]
            }
          })
        }
        if (this.tableList.length) {
          this.multiple = this.tableList[0].comboType
          if (this.expand === false) {
            this.comboExpand = 0
          } else {
            this.comboExpand = this.tableList[0].comboExpand
          }

          this.filterable = !this.multiple
          if (this.activeUrl.includes('oc=0')) {
            this.multiple = true
          }
          if (this.activeUrl.includes('oc=1')) {
            this.multiple = true
          }
          if (this.activeUrl.includes('doctors')) {
            this.multiple = true
          }
        }
      })
    }
  }
}
</script>
