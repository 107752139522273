<style lang="less" scoped>
.crt-content {
  z-index: 99;
  position: fixed;
}

.new-css {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
}

.input-form-item-content {
  // width: 180px !important;
}
.el-divider__text.is-left {
  font-size: 18px;
}
.top-item-row {
  margin: 18px 15px;
  display: flex;
  justify-content: center;
  .item-row {
    display: flex;
    justify-content: flex-end;
    // padding-left: 27px;
    min-width: 60px;
    .el-date-editor .el-range__icon {
      line-height: 19px;
    }
    .el-date-editor .el-range-separator {
      line-height: 19px;
    }
    .el-date-editor .el-range__close-icon {
      line-height: 19px;
    }
    .el-icon-time:before {
      content: '' !important;
    }
    .el-icon-date:before {
      content: '' !important;
    }
    .el-icon-arrow-up:before {
      content: '' !important;
    }

    .time {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    span {
      margin: 0 15px;
    }
    .item-content {
      width: 180px !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      .el-date-editor.el-input {
        width: 180px;
      }
      .el-select {
        /deep/.el-input--suffix {
          display: inline-block;
        }
        /deep/.el-input--suffix .el-input__inner {
          height: 30px !important;
        }
      }
    }
  }
}
</style>

<template>
  <CmDialogBorder size="mini" :zIndex="zIndex" title="手术更新" v-show="isShow" @close="Close">
    <div class="crt-content new-css">
      <h3 style="margin-left: 30px">
        <span>科室：{{ department }}</span>
      </h3>
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="open_save"
      >
        保存
      </el-button>
    </div>
    <div class="header" style="height: 88px"></div>
    <div class="top-item-row">
      <div class="item-row">
        <span>更新日期:</span>
        <div class="input-form-item-content">
          <date-panel
            v-model="patientInfoInput.updateDate"
            type="date"
            align="center"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
          ></date-panel>
        </div>
      </div>
    </div>
    <div class="header" style="height: 48px"></div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../components/CmDialogBorder.vue'
// import { PopupManager } from 'element-ui/lib/utils/popup'
import DatePanel from '../Menu/components/date-picker/index'

export default {
  name: 'UpdateOperationDialog',
  components: {
    CmDialogBorder,
    DatePanel
  },

  data() {
    return {
      isShow: false,
      zIndex: 9,
      department: sessionStorage.getItem('department'),
      patientInfoInput: {
        updateDate: '' //更新时间
      }
    }
  },
  props: {},
  mounted() {
    if (this.department == 'null') {
      this.department = undefined
    }
  },
  computed: {},
  methods: {
    Show() {
      this.patientInfoInput.updateDate = this.dayjs().format('YYYY-MM-DD')
      this.isShow = true
    },
    open_save() {
      if (this.patientInfoInput.updateDate == '') {
        this.$message({
          message: '请输入更新时间',
          type: 'warning'
        })
      } else {
        this.Save()
      }
    },
    Save() {

      let params = {
        startDate: this.patientInfoInput.updateDate,
        endDate: this.patientInfoInput.updateDate
      }

      this.$api.post('/v1/webconsole/sync/CISReport', params).then(
        (res) => {
          if (res.data && res.data.data) {
            this.$message.success('更新成功')
            this.patientInfoInput = {}
            this.isShow = false
            this.$emit('save')
          }
        },
        () => this.$message.error('更新失败')
      )
    },
    Close() {
      this.isShow = false
      this.patientInfoInput = {}
    }
  }
}
</script>
