<template>
  <!-- ai的message弹窗内容 -->
  <transition name="taataa">
    <div :class="['plugins-message-box', type]" v-if="visible">
      <div :class="['message-icon', 'iconfont', iconClass]">
        <div class="spantext">{{ message }}</div>
      </div>
    </div>
  </transition>
</template>

<script>
const typeClass = {
  success: 'icon-success',
  error: 'icon-error',
  warning: 'icon-warn',
  info: 'icon-info'
}
export default {
  name: 'messageMain',
  data() {
    return {
      visible: false, // 控制DOM显示隐藏
      type: 'success', // 默认type值为default
      icon: '', // 默认使用icon为空，则使用type值对应的icon
      message: '', // 默认的message为空，由外部传入
      duration: 2000 // 默认显示时间为2000ms
    }
  },
  computed: {
    // 如果外部传入icon则使用外部的icon，如果没有。则使用type值对应的icon
    iconClass() {
      if (this.icon) {
        return this.icon
      } else {
        return typeClass[this.type]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.plugins-message-box {
  position: fixed;
  z-index: 989;
  left: 50%;
  top: 32px;
  transform: translate(-50%, 0%);
  -webkit-animation: comein 0.5s linear;
  animation: comein 0.5s linear;
  pointer-events: none;
  .message-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 160px;
    height: auto;
    padding: 10px 20px;
    background: #fff;
    border-radius: 6px;
    color: #00b552;
    text-align: left;
    font-size: 14px;
    position: relative;
    .spantext {
      display: inline-block;
      white-space: pre-wrap;
      word-wrap: break-word;
      height: auto;
      line-height: 20px;
      margin-left: 5px;
      color: #000;
    }
  }

  .icon-success {
    min-width: 200px;
    height: auto;
    padding: 20px 60px 20px 100px;
    background: #fff;
    border-radius: 6px;
    color: #000;
    text-align: left;
    font-size: 16px;
    position: relative;
    .spantext {
      display: inline-block;
      width: 100%;
      // 换行设置
      white-space: pre-wrap;
      word-wrap: break-word;
      height: auto;
      line-height: 20px;
      // @include ellipsis(5);
    }
  }

  .icon-info {
    min-width: 200px;
    height: auto;
    padding: 20px 60px 20px 100px;
    background: rgba(33, 80, 109, 0.8);
    border-radius: 6px;
    color: #c8e3fd;
    text-align: left;
    font-size: 16px;
    position: relative;
    .spantext {
      display: inline-block;
      width: 100%;
      // 换行设置
      white-space: pre-wrap;
      word-wrap: break-word;
      height: auto;
      line-height: 20px;
      // @include ellipsis(5);
    }
  }
}
@keyframes comein {
  0% {
    top: -10%;
    opacity: 0.3;
  }
  100% {
    top: 32px;
    opacity: 1;
  }
}
</style>
