// rem等比适配配置文件

// 检测是否为 Mac 设备
function isMac() {
  // 如果浏览器支持 userAgentData
  if (navigator.userAgentData) {
    return navigator.userAgentData.platform === 'macOS'
  }

  // 兼容性处理，使用 userAgent 检测 Mac
  return /Mac/i.test(navigator.userAgent)
}

// 设置缩放比例函数
function setZoom() {
  const devicePixelRatio = window.devicePixelRatio || 1 // 获取设备的像素比
  console.log('当前设备像素比:', devicePixelRatio)

  // 检测是否为 Mac 平台
  if (isMac()) {
    return 1 // 在 Mac 上保持 1x 缩放比例，不额外处理
  }

  // 针对非 Mac 设备，如果像素比不为 1，则返回缩放比例
  if (devicePixelRatio !== 1) {
    return 1 / devicePixelRatio + 0.2 // 添加 0.2 的调整，确保缩放合理性
  }

  // 默认返回缩放比例 1
  return 1
}

const zoom = setZoom()

// 基准大小
const baseSize = 16

// 设置 rem 函数
function setRem() {
  // const clientWidth = document.documentElement.clientWidth
  // let scale = clientWidth / 1920 // 根据页面宽度设置缩放比例（以 1920px 为基准）

  // // 如果页面宽度较小，调整缩放比例
  // if (clientWidth < 1180) {
  //   scale = clientWidth / 1180
  // }
  // console.log(scale)

  // // 基于 zoom 和 scale 计算最终的 font-size
  // document.documentElement.style.fontSize = baseSize * scale * zoom + 'px'
  document.documentElement.style.fontSize = baseSize * zoom + 'px'
}

// 初始化
setRem()

// 使用 requestAnimationFrame 优化 onresize 事件
let resizeTimeout = null
window.onresize = function () {
  if (resizeTimeout) {
    cancelAnimationFrame(resizeTimeout)
  }
  resizeTimeout = requestAnimationFrame(() => {
    setRem()
  })
}
