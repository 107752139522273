<style lang="less" scoped>
.crt-content {
  z-index: 99;
  position: fixed;
}

.new-css {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
}

.input-form-item-content {
  width: 180px !important;
}
.el-divider__text.is-left {
  font-size: 18px;
}
.top-item-row {
  margin: 18px 15px;
  display: flex;
  justify-content: flex-start;
  .item-row {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding-left: 27px;
    min-width: 60px;
    .el-date-editor .el-range__icon {
      line-height: 19px;
    }
    .el-date-editor .el-range-separator {
      line-height: 19px;
    }
    .el-date-editor .el-range__close-icon {
      line-height: 19px;
    }
    .el-icon-time:before {
      content: '' !important;
    }
    .el-icon-date:before {
      content: '' !important;
    }
    .el-icon-arrow-up:before {
      content: '' !important;
    }

    .time {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    span {
      margin: 0 15px;
    }
    .item-content {
      width: 180px !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      .el-date-editor.el-input {
        width: 180px;
      }
      .el-select {
        /deep/.el-input--suffix {
          display: inline-block;
        }
        /deep/.el-input--suffix .el-input__inner {
          height: 30px !important;
        }
      }
    }
  }
}
</style>

<template>
  <CmDialogBorder size="mini" :zIndex="zIndex" title="手术登记" v-show="isShow" @close="Close">
    <div class="crt-content new-css">
      <h3 style="margin-left: 30px">
        <span>科室：{{ department }}</span>
        <!-- <span style="margin-left: 10px">申请时间：{{ patientInfoInput.studyApplyTime }}</span> -->
      </h3>
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="open_save"
      >
        保存
      </el-button>
    </div>
    <div class="header" style="height: 48px"></div>
    <div class="top-item-row">
      <div class="item-row">
        <span>
          患者姓名
          <span style="margin: 0; color: red">*</span>
        </span>
        <div>
          <el-input
            class="input-form-item-content"
            placeholder="请输入"
            type="text"
            v-model="patientInfoInput.patientsName"
            size="mini"
          ></el-input>
        </div>
      </div>
      <div class="item-row"></div>

      <div class="item-row">
        <span>
          病案号
          <span style="margin: 0; color: red">*</span>
        </span>
        <div>
          <el-input
            class="input-form-item-content"
            placeholder="请输入"
            type="text"
            v-model="patientInfoInput.patientsID"
            size="mini"
          ></el-input>
        </div>
      </div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>
          手术名称
          <span style="margin: 0; color: red">*</span>
        </span>
        <div class="input-form-item-content">
          <el-select
            v-model="examineNameInfo"
            :clearable="true"
            multiple
            filterable
            allow-create
            default-first-option
            remote
            reserve-keyword
            size="mini"
            :remote-method="remoteOperation"
            placeholder="请选择"
            @change="changeSearchSurgeryOperation"
          >
            <el-option
              v-for="(item, index) in pubOperationList"
              :key="index"
              :label="item.dictName"
              :value="{ value: item.dictIndex, label: item.dictName }"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="item-row"></div>
      <div class="item-row">
        <span>
          主刀医生
          <span style="margin: 0; color: red">*</span>
        </span>
        <div class="item-content">
          <el-select v-model="examineDoctor" placeholder="请选择" @change="changeExamineDoctor">
            <el-option
              v-for="item in doctorList"
              :key="item.userId"
              :label="item.userName"
              :value="{ value: item.userId, label: item.userName }"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <!-- <div class="top-item-row">
      <div class="item-row">
        <span>
          手术时间
          <span style="margin: 0; color: red">*</span>
        </span>
        <div>
          <date-panel
            class="input-form-item-content"
            v-model="patientInfoInput.studyDate"
            type="datetime"
            align="center"
            value-format="yyyy-MM-dd HH:mm"
            format="yyyy-MM-dd HH:mm"
          ></date-panel>
        </div>
      </div>
    </div> -->
    <div style="height: 20px"></div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../components/CmDialogBorder.vue'
// import CmSelect from '../../../components/CmSelect.vue'
// import { PopupManager } from 'element-ui/lib/utils/popup'
// import DatePanel from '../../Menu/components/date-picker/index'

export default {
  name: 'PatientInfoDialog',
  components: {
    CmDialogBorder,
    // CmSelect,
    // DatePanel
  },

  data() {
    return {
      isShow: false,
      zIndex: 9,
      department: sessionStorage.getItem('department'),
      deptId: sessionStorage.getItem('deptId'),
      examineDoctor: '',
      examineNameInfo: '',
      examineList: [], //
      patientInfoInput: {
        examineNames: '', //手术名称
        studyApplyTime: '', //手术申请时间 yyyy-MM-dd HH:mm  (科室申请手术时的日志时间, 这时候填写拟手术日期)
        examineDoctor: '',
        examineDoctorId: '', //主刀医生id
        patientsName: '', //患者姓名
        patientsID: '' //病案号
        // studyDate: '' //
      },
      pubOperationList: []
    }
  },
  props: {
    doctorList: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    if (this.department == 'null') {
      this.department = undefined
    }
  },
  computed: {},
  methods: {
    changeSearchSurgeryOperation(val) {
      if (val) {
        let arr = []
        let arrId = []
        let arrList = []
        for (let i = 0; i < val.length; i++) {
          const ele = val[i]
          arr.push(ele?.label || ele)
          arrId.push(ele?.value || 'undefined')
          arrList.push({
            dictIndex: ele?.value || 'undefined',
            dictName: ele?.label || ele
          })
        }
        this.patientInfoInput.examineNames = arr.join('|')
        this.patientInfoInput.examineIds = arrId.join('|')
        this.examineList = arrList
      }
    },
    remoteOperation(query) {
      if (query !== '') {
        setTimeout(() => {
          this.getPubOperationName(query)
        }, 200)
      } else {
        this.pubOperationList = []
      }
    },
    getPubOperationName(query) {
      if (query) {
        this.queryGet = query
        this.operationUrl = `/v1/webconsole/study/puboperationlist?keyword=${this.queryGet}`
      } else {
        this.operationUrl = `/v1/webconsole/study/puboperationlist`
      }
      this.$api.get(this.operationUrl).then((res) => {
        let arr = []
        if (res.data.data.length > 0) {
          res.data.data.map((item) => {
            arr.push({ dictName: item.dictName, dictIndex: item.dictIndex })
          })
          this.pubOperationList = arr
          for (let i = 0; i < this.examineList.length; i++) {
            const element = this.examineList[i]
            this.pubOperationList.push({
              dictName: element.dictName,
              dictIndex: element.dictIndex
            })
          }
        }
      })
    },
    formatUtcTime() {
      let date = new Date()
      return (
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
        '-' +
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
        ' ' +
        date.getHours() +
        ':' +
        (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
        ':' +
        (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
      )
    },

    changeExamineDoctor(val) {
      this.patientInfoInput.examineDoctor = val.label
      this.patientInfoInput.examineDoctorId = val.value
    },
    Show() {
      this.patientInfoInput.studyApplyTime = this.formatUtcTime()
      this.getPubOperationName()
      this.isShow = true
    },
    open_save() {
      if (
        this.patientInfoInput.patientsName == '' ||
        this.patientInfoInput.patientsID == '' ||
        this.patientInfoInput.examineDoctor == '' ||
        // this.patientInfoInput.studyDate == '' ||
        this.examineNameInfo == ''
      ) {
        this.$message({
          message: '请完整输入姓名、病案号、手术名称、主刀医生',
          type: 'warning'
        })
      } else {
        this.$confirm('是否保存患者登记信息?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.patientInfoInput.deptName = this.department
            this.patientInfoInput.deptId = this.deptId
            this.Save()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消保存'
            })
          })
      }
    },
    Save() {
      this.$api.post('/v1/webconsole/study/register', { ...this.patientInfoInput }).then(
        (res) => {
          if (res.data && res.data.data) {
            this.$message.success('登记成功')
            this.patientInfoInput = {}
            this.examineNameInfo = ''
            this.examineDoctor = ''
            this.isShow = false
            this.$emit('save')
            if (res.data.data.uuid) {
              setTimeout(() => {
                sessionStorage.setItem('patientInfo', JSON.stringify(res.data.data))
                const { href } = this.$router.resolve({
                  path: `/Detail/Baseinfo?operationId=${res.data.data.uuid}`
                })
                window.open(href, '_blank')
              }, 500)
            }
          }
        },
        () => this.$message.error('登记失败')
      )
    },
    Close() {
      this.isShow = false
      this.patientInfoInput = {}
      this.examineNameInfo = ''
      this.examineDoctor = ''
    }
  }
}
</script>
