<template>
  <!-- 只有左/右 -->
  <div v-if="slotType == 'both'" :class="classname">
    <template v-if="btnPosition == '左'">
      <slot v-if="isLeftShow" name="left"></slot>
      <slot v-if="isRightShow" name="right"></slot>
    </template>
    <template v-else>
      <div :class="isFlex ? 'flex-right' : 'right-flex'">
        <slot v-if="isRightShow" name="right"></slot>
        <slot v-if="isLeftShow" name="left"></slot>
      </div>
    </template>
  </div>
  <!-- 分三种情况  左----右----其他（默认放到左边） -->
  <div v-else-if="slotType === 'another'" class="crt-content">
    <template v-if="btnPosition === '左'">
      <div class="crt-content-left">
        <slot name="left"></slot>
        <slot name="another"></slot>
      </div>
      <div class="crt-content-right">
        <slot name="right"></slot>
      </div>
    </template>
    <template v-else>
      <div class="crt-content-left">
        <slot name="another"></slot>
      </div>
      <div class="crt-content-right right-flex">
        <slot name="right"></slot>
        <slot name="left"></slot>
      </div>
    </template>
  </div>
  <!-- 分两种情况  左----右 -->
  <div v-else class="crt-content">
    <template v-if="btnPosition == '左'">
      <slot></slot>
      <slot name="left"></slot>
    </template>
    <template v-else>
      <div class="right-flex">
        <slot></slot>
        <slot name="right"></slot>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'HeaderBtnUniversal',
  components: {},
  props: {
    // 插槽个数类型（两个、三个）
    slotType: {
      type: String,
      default: () => ''
    },
    // 自定义父级类名
    classname: {
      type: String,
      default: () => 'crt-content'
    },
    // 是否用flex布局右边
    isFlex: {
      type: Boolean,
      default: () => false
    },
    // 是否展示左边
    isLeftShow: {
      type: Boolean,
      default: () => true
    },
    // 是否展示右边
    isRightShow: {
      type: Boolean,
      default: () => true
    }
  },
  created() {
    this.getBtnPositionFun()
  },
  computed: {},
  watch: {},
  data() {
    return {
      btnPosition: '左'
    }
  },
  mounted() {},
  methods: {
    getBtnPositionFun() {
      this.$api.get(`/v1/webconsole/sys/defaultValue?code=${'BUTTON_POSITION'}`).then((res) => {
        this.btnPosition = res.data.data || '左'
        sessionStorage.setItem('btnPosition', res.data.data || '左')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.flex-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-right {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.right-flex {
  position: absolute;
  right: 20px;
  display: flex;
  align-items: center;
}
.save-button {
  padding-left: 10px;
  margin-right: 0.5rem;
}
.crt-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .crt-content-left {
    margin-left: 20px;

    height: 28px;
    line-height: 28px;
    width: 55%;
    display: flex;
    .block {
      margin-left: 10px;
      .el-input {
        width: 160px;
      }
      .demonstration {
        margin: 0 10px;
      }
    }
  }
  .crt-content-right {
    display: flex;
    margin-right: 20px;
    height: 28px;
    line-height: 28px;
  }
}
</style>
