<style lang="less" scoped></style>

<template>
  <!-- 修改签名/密码弹窗 -->
  <el-dialog
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    v-dialogDrag
    :title="'用户密码修改'"
    :visible.sync="edit_visible"
    width="30%"
    :show-close="false"
  >
    <el-alert
      v-show="showTips"
      style="margin-bottom: 20px"
      title="密码失效，请更新密码"
      type="warning"
      :closable="false"
    ></el-alert>
    <el-form :model="editpwd_form" :rules="rules" ref="editpwd_form" label-width="100px">
      <el-form-item label="账号:" prop="userCode">
        <el-tag type="info">{{ editpwd_form.userCode }}</el-tag>
      </el-form-item>
      <el-form-item label="原密码:" prop="oldPwd" required>
        <el-input
          v-model="editpwd_form.oldPwd"
          placeholder="请输入"
          style="width: 60%"
          type="password"
        ></el-input>
      </el-form-item>
      <el-form-item label="新密码:" prop="newPwd" required>
        <el-input
          v-model="editpwd_form.newPwd"
          placeholder="请输入"
          style="width: 60%"
          type="password"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码:" prop="confimpwd" required>
        <el-input
          v-model="editpwd_form.confimpwd"
          placeholder="请输入"
          style="width: 60%"
          type="password"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel_editpwd('editpwd_form')" size="mini">取 消</el-button>
      <el-button type="primary" @click="determine_pwd('editpwd_form')" size="mini">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { clearCookie } from '@/assets/js/cookieUtils'

export default {
  name: 'ChangePwd',
  components: {},
  data() {
    return {
      edit_visible: false,
      editpwd_form: {
        userCode: '',
        oldPwd: '',
        newPwd: '',
        confimpwd: ''
      },
      strongPassword: false,
      //修改密码校验规则
      rules: null,
      showTips: true
    }
  },
  props: {},
  watch: {
    strongPassword: {
      handler(val) {
        if (val) {
          this.rules = {
            userCode: [{ required: false, message: '请输入账号', trigger: 'blur' }],
            oldPwd: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
            newPwd: [
              { required: true, message: '请输入新密码', trigger: 'blur' },
              { validator: this.validatePassword, trigger: 'blur' }
            ],
            confimpwd: [{ validator: this.validatePass2, trigger: 'blur' }]
          }
        } else {
          this.rules = {
            userCode: [{ required: false, message: '请输入账号', trigger: 'blur' }],
            oldPwd: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
            newPwd: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
            confimpwd: [{ validator: this.validatePass2, trigger: 'blur' }]
          }
        }

        // 清理原有校验结果，重新校验
        if (this.$refs.editpwd_form) {
          this.$refs.editpwd_form.clearValidate() // 确保清除校验状态
          this.$refs.editpwd_form.validate() // 重新触发校验
        }
      }
    }
  },
  mounted() {},
  computed: {},
  methods: {
    //取消密码修改
    cancel_editpwd(formName) {
      this.edit_visible = false
      this.$refs[formName].resetFields()
    },

    Show() {
      this.edit_visible = true
      const loginInfo = JSON.parse(sessionStorage.getItem('loginInfo'))

      this.editpwd_form.userCode = loginInfo.userCode
      this.strongPassword = loginInfo.strongPassword
    },

    changeShowTips() {
      this.showTips = false
    },

    validatePass2(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.editpwd_form.newPwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    },
    // 自定义校验逻辑
    validatePassword(rule, value, callback) {
      const username = this.editpwd_form.userCode

      // 条件 1: 至少8位，包含字母、数字和特殊字符
      const lengthPattern =
        /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[a-zA-Z\d!@#$%^&*(),.?":{}|<>]{8,}$/

      // 条件 2: 检查数字部分是否连续递增或递减
      const hasSequentialNumbers = (password) => {
        const numStr = password.replace(/[^0-9]/g, '') // 提取数字部分

        const diff = numStr[1] - numStr[0] // 计算第一个差值

        // 必须是递增（+1）或递减（-1）
        if (Math.abs(diff) !== 1) return false

        // 遍历剩余数字，验证是否每个差值相同
        for (let i = 1; i < numStr.length - 1; i++) {
          if (numStr[i + 1] - numStr[i] !== diff) {
            return false
          }
        }
        return true
      }
      // 条件 3: 密码不能包含用户名（忽略大小写）
      const usernamePattern = new RegExp(username, 'i')

      // 校验规则
      if (!value) {
        callback(new Error('密码不能为空'))
      } else if (!lengthPattern.test(value)) {
        callback(new Error('密码必须至少8位，包含字母、数字和特殊字符'))
      } else if (hasSequentialNumbers(value)) {
        callback(new Error('密码不能包含连续递增或递减的数字'))
      } else if (username && usernamePattern.test(value)) {
        callback(new Error('密码不能包含用户名'))
      } else {
        callback() // 校验通过
      }
    },

    //确定密码修改
    determine_pwd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 判断是否是签名
          let url
          // url = `/v1/webconsole/user/login/change/password?oldPwd=${this.editpwd_form.oldPwd}&newPwd=${this.editpwd_form.newPwd}&userCode=${this.editpwd_form.userCode}`
          url = `/v1/webconsole/user/change/password?oldPwd=${this.editpwd_form.oldPwd}&newPwd=${this.editpwd_form.newPwd}`
          this.axios.put(url).then((res) => {
            if (res.data.status == 200) {
              this.msg = this.$message({
                duration: 1000,
                type: 'success',
                message: '修改成功，请登录！'
              })
              this.edit_visible = false

              if (!this.showTips) {
                setTimeout(() => {
                  this.$confirm('请重新登录', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    showCancelButton: false,
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                    type: 'error'
                  }).then(() => {
                    this.axios.post('/v1/webconsole/user/logout'),
                      sessionStorage.clear(),
                      localStorage.clear(),
                      clearCookie(),
                      this.$router.replace('/')
                  })
                }, 500)
              } else {
                sessionStorage.clear()
                localStorage.clear()
                clearCookie()
              }
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              })
            }
          })
        }
      })
    }
  }
}
</script>
