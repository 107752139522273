let SERVER_HOST = ''
let ADMIN_SERVER_HOST = ''
let PDF_HOST = window.location.origin

let dev = 'kf' // 开发版本
// let dev = 'ze' // 浙二
// let dev = 'zs' // 浙四
// let dev = 'iv' // 线上
if (dev === 'kf') {
  // 开发版本
  SERVER_HOST = window.location.origin + '/api'
  // ADMIN_SERVER_HOST = 'http://localhost:8080/#/login'
  // ADMIN_SERVER_HOST = window.location.origin + '/admin'
  // ADMIN_SERVER_HOST = 'http://http://iv.dev.hzcqt.com/admin'
  ADMIN_SERVER_HOST = 'http://192.168.225.58:8080/' // 巍
} else if (dev === 'ze') {
  // 浙二
  SERVER_HOST = 'http://192.168.225.58:8888/api'
  ADMIN_SERVER_HOST = 'http://192.168.225.58:8083/admin'
} else if (dev === 'zs') {
  // 浙四
  SERVER_HOST = 'http://172.17.11.72:80/api'
  ADMIN_SERVER_HOST = 'http://172.17.11.72:8083/admin'
} else if (dev === 'iv') {
  // 线上
  SERVER_HOST = 'http://iv.hzcqt.com/api'
  // SERVER_HOST = 'http://iv.dev.hzcqt.com/api'
  // ADMIN_SERVER_HOST = 'http://iv.hzcqt.com:8080'
  ADMIN_SERVER_HOST = 'http://localhost/:8083'
}

export { SERVER_HOST, ADMIN_SERVER_HOST, PDF_HOST }
