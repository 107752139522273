function setCookie (key, value, exdays) {
    if (typeof value == "object") {//如果要存储对象，则先转为json串
        value = window.JSON.stringify(value);
    }
    if (exdays) {
        let d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toGMTString();
        document.cookie = `${key}=${value};${expires}`;
    } else {
        document.cookie = `${key}=${value}`
    }
}
function getCookie (key) {
    let name = key + '=';
    let cookies = document.cookie.split(';');
    let value = ''
    for (var i = 0; i < cookies.length; i++) {
        var c = cookies[i].trim();
        if (c.indexOf(name) == 0) {
            value = c.substring(name.length, c.length);
            break;
        }
    }
    if (value === '') return value;
    let json = '';
    try {
        json = window.JSON.parse(value);//json串转为js对象
        // eslint-disable-next-line no-empty
    } catch {

    }
    if (typeof json == "object" && json) {//利用了一点，当符合json格式，串会成功转为js对象，否则为null
        return json;
    }
    return value;

}

function checkCookie (key) {//cookie中是否存在指定key
    return getCookie(key) ? true : false;
}
function clearCookie() {
    var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
    if (keys) {
        for (var i = keys.length; i--;) {
            document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString();//清除当前域名下的,例如：m.kevis.com
            document.cookie = keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString();//清除当前域名下的，例如 .m.kevis.com
            document.cookie = keys[i] + '=0;path=/;domain=kevis.com;expires=' + new Date(0).toUTCString();//清除一级域名下的或指定的，例如 .kevis.com
        }
    }
    
}

export {
    setCookie,
    getCookie,
    checkCookie,
    clearCookie,
}