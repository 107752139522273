<style scoped lang="less">
.cm-body {
  padding: 20px 0;
}
.button-panel {
  width: 100%;
  bottom: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
.top-item-row {
  margin: 5px 15px;
  display: flex;
  justify-content: flex-start;
}

.item-row {
  margin: 0.5rem 1rem 0rem 4rem;
  display: flex;
}
.item-row > span {
  width: 100px;
  margin: 5px 20px 5px 0;
}
.item-content {
  width: 220px;
}

/deep/.disabled-input {
  .el-input__inner {
    color: #000 !important;
  }
}

.dialog-baseInfo {
  margin: 0.5rem 1rem 1rem 4rem;

  div {
    margin-right: 10px;
  }

  .name {
    font-size: 1.325rem;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}
</style>

<template>
  <CmDialogBorder size="mini" :zIndex="zIndex" title="手术时间安排" v-show="isShow" @close="Close">
    <div class="cm-body">
      <!-- <div class="top-item-row">
        <div class="item-row">
          <span>姓名</span>
          <div class="item-content" style="display: flex">
            <el-tag type="info">{{ schedulingAllInfo?.patientsName|| '--' }}</el-tag>
          </div>
        </div>
      </div>
      <div class="top-item-row">
        <div class="item-row">
          <span>病案号</span>
          <div class="item-content" style="display: flex">
            <el-tag type="info">{{ schedulingAllInfo?.patientsID|| '--' }}</el-tag>
          </div>
        </div>
      </div>
      <div class="top-item-row">
        <div class="item-row">
          <span>床位号</span>
          <div class="item-content" style="display: flex">
            <el-tag type="info">{{ schedulingAllInfo?.bedNo || '--' }}</el-tag>
          </div>
        </div>
      </div> -->

      <div class="top-item-row">
        <div class="dialog-baseInfo">
          <div class="name">{{ schedulingAllInfo?.patientsName || '--' }}</div>
          <div class="flex">
            <div>病案号：{{ schedulingAllInfo?.patientsID || '--' }}</div>
            <div style="margin-left: 10px">床位号：{{ schedulingAllInfo?.bedNo || '--' }}</div>
          </div>
        </div>
      </div>

      <div class="top-item-row">
        <div class="item-row">
          <span class="form-item-label">计划手术时间</span>
          <div class="item-content" style="display: flex">
            <date-panel
              v-model="planStudyDate"
              type="datetime"
              align="center"
              value-format="yyyy-MM-dd HH:mm"
              format="yyyy-MM-dd HH:mm"
            ></date-panel>
          </div>
        </div>
      </div>

      <div class="top-item-row">
        <div class="item-row">
          <span class="">次序</span>
          <div class="item-content">
            <el-input
              type="text"
              v-model="schedulingInfo.doctorNumber"
              size="mini"
            ></el-input>
          </div>
        </div>
      </div>

      <div class="top-item-row">
        <div class="item-row">
          <span class="">重要事项</span>
          <div class="item-content" style="display: flex">
            <el-input
              type="textarea"
              v-model="schedulingInfo.importantItem"
              size="mini"
              :rows="3"
              placeholder="请输入"
            ></el-input>
          </div>
        </div>
      </div>

      <div class="button-panel">
        <el-button
          type="success"
          class="common-button commonBtn"
          icon="el-icon-finished"
          v-debounce="Save"
        >
          保存
        </el-button>
        <el-button
          type="danger"
          class="common-button commonBtn"
          icon="el-icon-close"
          v-debounce="Close"
        >
          关闭
        </el-button>
      </div>
    </div>
  </CmDialogBorder>
</template>

<script>
import DatePanel from '../../Menu/components/date-picker/index'
import CmDialogBorder from '../../../components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'

export default {
  name: 'SchedulingDialog',
  components: {
    CmDialogBorder,

    DatePanel
  },
  data() {
    return {
      zIndex: 99,
      isShow: false,
      operationId: '',
      planStudyDate: '',
      schedulingInfo: {
        planStudyDate: '',
        doctorNumber: '',
        planStudyTime: '',
        importantItem: ''
      },
      schedulingAllInfo: null
    }
  },
  methods: {
    formatUtcDate(v) {
      let date = v ? new Date(v) : new Date()
      return (
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
        '-' +
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
      )
    },
    formatUtcTime(v) {
      if (v) {
        let date = new Date(v)
        return (
          date.getHours() +
          ':' +
          (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
        )
      } else {
        return ''
      }
    },
    getInfo() {
      this.$api.get(`/v1/webconsole/study/doctor/plan/schedule/${this.operationId}`).then((res) => {
        if (res.data?.data) {
          let infoDate = res.data.data.planStudyDate
            ? res.data.data.planStudyDate.substring(0, 10)
            : ''
          let infoTime = res.data.data.planStudyTime ? res.data.data.planStudyTime : '00:00'
          if (infoDate) {
            this.planStudyDate = infoDate + ' ' + infoTime
          } else {
            this.planStudyDate = ''
          }

          this.schedulingInfo.doctorNumber = res.data.data.doctorNumber
          this.schedulingInfo.importantItem = res.data.data.importantItem

          this.schedulingAllInfo = res.data.data
        }
      })
    },
    Show(bloodVessel) {
      this.operationId = bloodVessel.uuid
      this.getInfo()

      this.isShow = true
    },
    Save() {
      if (!this.planStudyDate) {
        return this.$message.warning('计划手术时间不能为空！')
      }
      if (!this.schedulingInfo.doctorNumber) {
        return this.$message.warning('台次不能为空！')
      }
      this.schedulingInfo.planStudyDate = this.planStudyDate
      this.schedulingInfo.planStudyTime = this.planStudyDate.substring(11, 16)
      let data = {
        planStudyDate: this.schedulingInfo.planStudyDate || null,
        doctorNumber: this.schedulingInfo.doctorNumber || null,
        importantItem: this.schedulingInfo.importantItem || null,
        planStudyTime: this.schedulingInfo.planStudyTime || null
      }

      let formData = new FormData()
      for (let i in data) {
        const element = data[i]
        if (element !== null) {
          formData.append(i, element)
        }
      }
      this.$api
        .post(`/v1/webconsole/study/schedule/doctor/update/number/${this.operationId}`, formData)
        .then((res) => {
          if (res.data && res.data.data) {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
            this.$emit('save')
          } else {
            this.$message({
              message: res.data.msg,
              type: 'warning'
            })
          }
          this.isShow = false
        })
        .finally(() => {
          this.planStudyDate = ''
          this.schedulingInfo = {
            planStudyDate: '',
            doctorNumber: '',
            planStudyTime: '',
            importantItem: ''
          }

          this.schedulingAllInfo = null
        })
    },

    Close() {
      this.isShow = false
      this.planStudyDate = ''
      this.schedulingInfo = {
        planStudyDate: '',
        doctorNumber: '',
        planStudyTime: '',
        importantItem: ''
      }
      this.schedulingAllInfo = null
    }
  }
}
</script>
