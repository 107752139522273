<style scoped lang="less">
.dialog__mask {
  background-color: rgba(0, 0, 0, 0.5); /* 灰色背景，透明度为0.5 */
}
.dialog-content-title:hover {
  cursor: move;
}
.dialog-content-close-icon {
  float: right;
  padding: 10px 10px 0 10px;
  cursor: pointer;
}
.dialog-content__mini {
  min-width: 600px;
}
@media screen and (max-width: 1180px) {
  .dialog-content__big {
    width: 1100px;
  }
  .dialog-content__horizontal {
    width: 1000px;
  }
}
</style>

<template>
  <div class="dialog__mask" :style="`z-index:${zIndex}`">
    <div :class="`dialog-content dialog-content__${size}`" v-drag>
      <div class="dialog-content-title">
        <i class="dialog-content-title__inner_icon"></i>
        <span class="dialog-span-title">{{ title }}</span>
        <i class="el-icon-close dialog-content-close-icon" @click="closeIcon"></i>
      </div>
      <div class="dialog-content-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CmDialogBorder',
  components: {},
  props: {
    title: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default() {
        return ['tableList', 'big', 'horizontal', 'middle', 'small', 'mini']
      }
    },
    zIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    closeIcon() {
      this.$emit('close')
    }
  }
}
</script>
